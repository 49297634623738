import React from "react";
import {
  SEO,
  PageLayout,
  FadeReveal,
  MarkdownContent,
  Image,
  SlideReveal,
  multipleComponentIterator,
  BackgroundImageTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Grid, Header, List, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.namastenailsanctuary.com/about/"}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
              className={"markdown-column"}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "content_section_header",
                  defaultValue: "About",
                })}
              />
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"image-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "content_section_image",
                    numToSelect: 1,
                  })}
                  background={false}
                  className={"image"}
                  loadStrategy={"eager"}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"additional-section"}
            verticalAlign={"top"}
            textAlign={"left"}
            centered={true}
            column={"2"}
          >
            <Grid.Row stretched={true}>
              <Grid.Column width={8} textAlign={"left"}>
                <Header as={"h3"} content={"Uncompromising Hygiene & Safety"} />
                <div>
                  We prioritize your health with hospital-grade hygiene
                  protocols, exceeding state regulations to ensure a clean and
                  safe environment:
                </div>
                <List
                  bulleted={true}
                  items={[
                    "Hospital-Grade Autoclaves – All tools are sterilized to medical standards.",
                    "Single-Use Files & Buffers – Every guest receives a fresh set for safety.",
                    "Copper Pedicure Bowls with Disposable Liners – Providing a natural antibacterial surface with the highest sanitation.",
                    "Air Extraction Systems – Chemical-free, odorless salon air for a fresher experience. No Acrylics.",
                    "Non-Toxic & Healthy Products – We use safe, high-performance beauty solutions for your well-being.",
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={8} textAlign={"left"}>
                <Header
                  as={"h3"}
                  content={"Ultra-Zen Sanctuary for Complete Relaxation"}
                />
                <div>
                  Step into a tranquil escape designed to soothe your senses:
                </div>
                <List
                  bulleted={true}
                  items={[
                    "Zero-Gravity Chairs – The ultimate comfort for your relaxation.",
                    "Fully Enclosed Spa Sanctuary – A private retreat, completely soundproofed and visually separate from the reception area.",
                    "Personalized Aromatherapy & Sound Therapy – Request an herbal eye mask and noise-canceling headphones with relaxing music for an even deeper state ofrelaxation.",
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched={true}>
              <Grid.Column width={8} textAlign={"left"}>
                <Header
                  as={"h3"}
                  content={"Curated Selection of Premium Beauty Products "}
                />
                <div>
                  We believe in using only the best for your beauty and
                  wellness:
                </div>
                <List
                  bulleted={true}
                  items={[
                    "High-Quality, Non-Damaging Products – Strengthen and enhance your nails & skin while maintaining long-term health.",
                    "Ingredient-Conscious Selections – We carefully choose products based on performance, safety, and eco-friendliness.",
                    "Non-Drill Gel Removals Available – For a gentler and healthier nail care approach—simply let your technician know your preferences during consultation.",
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={8} textAlign={"left"}>
                <Header
                  as={"h3"}
                  content={"A Multi-Sensory Wellness Experience"}
                />
                <div>Every visit is a holistic journey for the senses:</div>
                <List
                  bulleted={true}
                  items={[
                    "Relaxing Aromas – Breathe in calming scents designed to enhance tranquility.",
                    "Soothing Sounds – Immerse yourself in meditative tunes for deep relaxation.",
                    "Tactile Comfort – Enjoy cozy, plush surroundings that make you feel pampered.",
                    "Visual Serenity – A beautifully curated environment that elevates your mood and encourages inner peace.",
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"about-staff-container"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Header
                as={"h2"}
                className={"about-staff-header"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_header",
                  defaultValue: "OUR TEAM",
                })}
              />
              <MarkdownContent
                className={"about-staff-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_description",
                })}
              />
              <Card.Group
                className={"about-featured-staff stylists"}
                itemsPerRow={3}
              >
                <SlideReveal
                  cascade={true}
                  triggerOnce={true}
                  className={"ui card"}
                  fade={true}
                  direction={"up"}
                  children={multipleComponentIterator({
                    iterator: allFishermanBusinessTeam.nodes,
                    limit: 3,
                    components: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                          />
                        ),
                        propMap: {
                          key: "_id",
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  })}
                />
              </Card.Group>
              <Button
                to={"/team/"}
                primary={true}
                as={InternalLink}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "see_all_team_button",
                  defaultValue: "See Our Team",
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
